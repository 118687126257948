function toggleDropdown(dropdownId) {
  // Zamykamy inne otwarte dropdowny
  document.querySelectorAll('.dropdown-content').forEach(dropdown => {
    if (dropdown.id !== dropdownId) {
      dropdown.classList.remove('show');
    }
  });

  // Przełączamy widoczność klikniętego dropdownu
  const dropdown = document.getElementById(dropdownId);
  if (dropdown) {
    dropdown.classList.toggle('show');
  }
}

// Zamknięcie dropdownów po kliknięciu poza nimi
window.onclick = function (event) {
  if (!event.target.matches('.dropbtn')) {
    document.querySelectorAll('.dropdown-content').forEach(dropdown => {
      dropdown.classList.remove('show');
    });
  }
};
